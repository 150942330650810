import Vue from 'vue';

export class VueRelay {
  static parse(tag, component, options) {
    let $els;

    if (tag instanceof HTMLElement === true) {
      $els = [tag];
    } else {
      $els = [...document.querySelectorAll(tag)];
    }

    return $els.map(($el) => new Vue({
      el: $el,

      // @see https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
      render: (createElement) => createElement(
        component,
        { class: $el.className, ...(options || JSON.parse($el.dataset.vue || '{}')) },
        $el.innerHTML,
      ),
    }));
  }
}

export default VueRelay;
