import '@/sass/styles.scss';
import 'alpinejs';
import 'axios';

import { VueRelay } from '@/scripts/VueRelay';

const vueComponents = [];

const customComponents = [
  ['body', () => import(/* webpackChunkName: "chunk-app" */ '@/scripts/InViewport')],
  ['[data-cookie-banner]', () => import(/* webpackChunkName: "chunk-app" */ '@/scripts/CookieBanner')],
  ['[data-video-src]', () => import(/* webpackChunkName: "chunk-app" */ '@/scripts/LazyLoadVideoSource')],
];

window.App = new (class App {
  constructor() {
    window.addEventListener('DOMContentLoaded', () => this.onReady());
  }

  onReady() {
    // Make env globally accessible
    // Needs data-env set on body
    window.app = {
      env: document.body.dataset.env,
    };

    // Initialize all custom components…
    customComponents.forEach(([tag, fn]) => {
      const $els = [...document.querySelectorAll(tag)];

      if ($els.length < 1) {
        return;
      }

      (async () => {
        const Constructor = (await fn()).default;

        $els.map(($el) => (new Constructor($el)).init($el));
      })();
    });

    // Initialize all single Vue components…
    vueComponents.forEach(([tag, fn]) => VueRelay.parse(tag, fn));

    window.axios = require('axios').default; // eslint-disable-line

    return this;
  }
})();
